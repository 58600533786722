.form-container {
	margin: 20px auto;
	width: 90%;
	max-width: 600px;
	padding: 3em;
	border-radius: 1.5em;
	position: relative;
}
.auth-form-heading {
	text-align: center;
	margin: 15px auto 30px;
}

.captcha-feedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
}

.form-submit-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.auth-btn-secondary {
	margin: 10px 16px;
	cursor: pointer;
	line-height: 1.3;
	overflow: hidden;
	padding: 12px 2px 2px 2px;
	font-weight: 400;
	color: black;
}

.auth-btn-secondary:hover {
	border-bottom: 2px solid black;
	margin-bottom: 8px;
	color: black;
	text-decoration: none;
}

.form-container button {
	margin: 10px 0 0 0;
	outline: none;
	cursor: pointer;
	line-height: 1.3;
	padding: 12px 18px;
	text-decoration: none;
}

.pw-form {
	width: 600px;
	margin: 2rem auto;
}

.form-container .logo-cont {
	position: absolute;
}
