.referrers {
	margin-top: 2rem;
}
.referrers-container {
	display: flex;
	min-height: 3rem;
}
.card-inner {
	display: flex;
	align-items: flex-start;
}

.card-inner h6 {
	margin: 0.5rem 1rem 0.5rem 0;
}

.referrer-form-group {
	display: flex;
}
.referrer-form-group .form-group {
	margin: 0 1rem 0;
}

.referrer {
	margin-right: 0.75rem;
	margin-bottom: 1rem;
}

.referrer .name {
	margin-right: 0.5rem;
	border-right: rgba(0, 0, 0, 0.2);
}

.referrer .close {
	color: #fff;
	line-height: 0.9;
	cursor: pointer;
}
