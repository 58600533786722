.main-container {
	width: 96%;
	margin: auto;
}

.main-container .title {
	margin: 1rem 4rem;
	display: flex;
}
.main-container .header-container .title {
	display: block;
}
.main-container .header-container.admin-dboard-header-container .title {
	display: flex;
}
.main-container .title h2 {
	margin: 0;
}
.main-container .title button {
	margin-left: 1rem;
}
.assign-admin {
	margin: 1rem 4rem;
	display: flex;
	align-items: center;
}
.assign-admin label {
	margin: 0 0.75rem 0 0;
}
.header-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 30px;
	margin-top: 1rem;
}

.export-to-csv {
	margin-top: 0.5rem;
}

.csv-link {
	margin-right: 1rem;
}

.hidden {
	display: none;
}

.tag-container {
	min-height: 54px;
}

.tag {
	margin-right: 0.75rem;
	margin-bottom: 1rem;
}

.tag .name {
	margin-right: 0.5rem;
	border-right: rgba(0, 0, 0, 0.2);
}

.tag .close {
	color: #fff;
	line-height: 0.9;
	cursor: pointer;
}
.tag-create {
	margin-left: 15px;
}

.csv-link,
#csv-download {
	color: #888;
	text-decoration: underline;
	margin-top: 0.5rem;
	cursor: pointer;
}

.csv-link,
#csv-download:hover {
	color: #888;
	text-decoration: underline;
	cursor: pointer;
}

.filters {
	display: flex;
	align-items: flex-start;
	margin: 1rem 4rem;
}

.flex {
	display: flex;
}
.flex-wrap {
	flex-wrap: wrap;
}
.items-center {
	align-items: center;
}
.filters input {
	padding: 0.4em;
	margin-left: 2rem;
	margin-bottom: 1rem;
	outline: none;
}

.submission-btn-container {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.submission-btn-container button {
	min-width: 6rem;
	margin: 0 1rem;
}

@media screen and (max-width: 1024px) {
	.header-container {
		flex-direction: column;
		justify-content: center;
	}
	.header-container h2 {
		text-align: center;
	}
	.filters {
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	.filters {
		flex-direction: column;
		align-items: flex-start;
		margin: 0 1em;
	}

	.filters select {
		margin: 0.5em 0;
	}
}

.view-link {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;
}

.view-link:hover {
	color: #0056b3;
	text-decoration: underline;
}

.sub-menu {
	background-color: #eee !important;
}
.pointer {
	cursor: pointer;
}
th.pointer:hover {
	background-color: #efefef;
}
.modal-dialog {
	max-width: 600px;
}
.pages {
	margin-right: 1.25rem;
}
.page-num {
	margin: 0 0.5rem;
	cursor: pointer;
}
.page-num.active {
	font-weight: 700;
}
.add-user-modal {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.add-user-modal button {
	margin-left: 1rem;
}
.add-user-modal .form-group {
	width: 75%;
}

.fixed-header-table tbody {
	display: block;
	max-height: 80vh;
	overflow: auto;
}
.fixed-header-table thead,
.fixed-header-table tbody tr {
	display: table;
	table-layout: fixed; /* even columns width , fix width of table too*/
	width: 100%;
}
.fixed-header-table td,
.fixed-header-table th {
	width: 300px;
}

.fixed-header-table td.x-half,
.fixed-header-table th.x-half {
	width: 150px;
}

.fixed-header-table td.x2,
.fixed-header-table th.x2 {
	width: 600px;
}
.fixed-header-table td.x3,
.fixed-header-table th.x3 {
	width: 900px;
}

.admin-header {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.admin-header .header-container {
	margin-bottom: 0;
}
