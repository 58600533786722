.conspire-nav {
	width: 100%;
	height: 60px;
	background-color: black;
	display: flex;
	justify-content: space-between;
	color: white;
	align-items: center;
}

.conspire-nav a {
	text-decoration: none;
	color: white;
	padding: 10px 30px;
}

.nav-btn-secondary {
	margin: 7px 30px;
	cursor: pointer;
	line-height: 1.3;
	overflow: hidden;
	padding: 3px 0;
	font-weight: 400;
	color: white;
}

.nav-btn-secondary:hover {
	border-bottom: 2px solid white;
	margin-bottom: 5px;
	color: white;
	text-decoration: none;
}
#basic-navbar-nav .logo-cont {
	width: 30px;
	margin-right: 1rem;
	margin-bottom: 0.25rem;
}
#basic-navbar-nav .logo-cont img {
	width: 100%;
	height: 100%;
}

.logo-cont {
	cursor: pointer;
}
